<template>
  <div class="navbar-right navbar-shrink-desktop">
    <ul class="navbar-nav">
      <li>
        <button
          type="button"
          class="account-toggler"
          @click="routeRedirect"
        >
          <span v-if="!isOnline">Login</span>
          <span v-else>Account</span>
          <span id="account-icon" class="icon" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'LoginButton',
	computed: {
		...mapGetters('user', ['isOnline']),
		routeRedirect() {
			var href = this.isOnline ? '/account' : '/login'
            
			return window.location.href = href
		},
	},
}
</script>

<style></style>
